import { Title } from '@solidjs/meta';
import { Label, Input, TextField, FieldDescription } from '@troon/ui';
import { Icon } from '@troon/icons';
import { StyleCard } from '../_style-card';

export default function InputPage() {
	return (
		<>
			<Title>Input | Forms | Style guide | Troon</Title>
			<h1 class="text-3xl font-semibold">Input</h1>
			<div class="mb-8 grid grid-cols-12 gap-4">
				<StyleCard title="TextField">
					<TextField name="textfield">
						<Label>This is a text field</Label>
						<Input />
					</TextField>

					<TextField name="textfield-with-description">
						<Label>This text field has a description</Label>
						<Input />
						<FieldDescription>This content describes what to do with the field.</FieldDescription>
					</TextField>
				</StyleCard>

				<StyleCard title="Alt TextField">
					<TextField name="textfield">
						<Label>This has a prefix</Label>
						<Input prefixElement={<Icon name="calendar" />} class="ps-8" placeholder="This is placehodler" />
					</TextField>

					<TextField name="textfield">
						<Label>This has a prefix and suffix</Label>
						<Input prefixElement="https://" class="pe-8 ps-16" suffixElement={<Icon name="globe" />} />
					</TextField>
				</StyleCard>

				<StyleCard title="Multiline">
					<TextField name="multi">
						<Label>This allows multiple lines</Label>
						<Input multiline />
					</TextField>
				</StyleCard>
			</div>
		</>
	);
}
